@import "~@ng-select/ng-select/themes/default.theme.css";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
@import 'simple-keyboard/build/css/index.css';
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
}
/*#region variables*/
/*#endregion*/
 /*#region Market Pos Buttons*/
.keyboard-btn {
  background: linear-gradient(56.32deg, rgba(85, 85, 85, 0.3) 0%, rgba(240, 240, 240, 0.3) 100%);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: none;
}
.keyboard-btn span {
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.gradient-gray-btn {
  background: linear-gradient(236.05deg, #ABABAB 0%, #E1E1E1 51.56%, #ABABAB 100%);
  color: #252525;
  font-family: "Poppins", sans-serif;
  font-size: 1.18rem;
  font-weight: 500;
  line-height: 30px;
  border: none;
  border-radius: 1rem;
  padding: 1.25rem 0.375rem;
  gap: 0.5rem;
  width: 8.375rem;
  height: 7.625rem;
  width: 100%;
}

.gradient-black-btn {
  background: linear-gradient(204.31deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.0378) 39.33%, rgba(255, 255, 255, 0) 62.52%), #252525;
  border-radius: 16px;
  color: #252525;
  font-family: "Poppins", sans-serif;
  font-size: 1.18rem;
  font-weight: 500;
  line-height: 30px;
  border: none;
  border-radius: 1rem;
  padding: 1.25rem 0.375rem;
  gap: 0.5rem;
  width: 8.375rem;
  height: 7.625rem;
  width: 100%;
}
.gradient-black-btn i {
  color: #e7473b;
}
.gradient-black-btn span {
  color: #FFFFFF;
}

.rectangle-red-btn {
  background: rgb(231, 71, 59);
  color: #FFFFFF;
  font-family: "Poppins", sans-serif;
  font-size: 1.18rem;
  font-weight: 500;
  line-height: 30px;
  border: none;
  border-radius: 8px;
  padding: 0.938rem 2.563rem;
}

.circle-red-btn,
.circle-white-btn {
  font-family: "Poppins", sans-serif;
  font-size: 1.18rem;
  font-weight: 500;
  line-height: 30px;
  border: none;
  padding: 0.625rem 1.25;
  gap: 0.625rem;
  padding: 0.9rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-red-btn {
  background: rgb(231, 71, 59);
  color: #FFFFFF;
}

.circle-white-btn {
  background: linear-gradient(112.61deg, #ECECEC 19.04%, #CACACA 44.4%, #ECECEC 69.23%);
  color: #e7473b;
}

.menu-red-btn {
  background: rgb(231, 71, 59);
  color: #FFFFFF;
  font-family: "Poppins", sans-serif;
  font-size: 1.18rem;
  font-weight: 500;
  line-height: 30px;
  border: none;
  border-radius: 1rem;
  padding: 1.25rem 0.375rem;
  gap: 0.5rem;
  width: 8.375rem;
  height: 7.625rem;
}

.menu-white-btn {
  background: #FFFFFF;
  color: #252525;
  font-family: "Poppins", sans-serif;
  font-size: 1.18rem;
  font-weight: 500;
  line-height: 30px;
  border: none;
  border-radius: 1rem;
  padding: 1.25rem 0.375rem;
  gap: 0.5rem;
  width: 8.375rem;
  height: 7.625rem;
}

.close-program {
  bottom: 1rem;
  right: 0;
}

.gray-line {
  background: #D9D9D9;
  width: 60px;
  height: 6px;
  margin: 0 auto;
  border-radius: 6px;
  position: absolute;
  top: 1rem;
  left: 0;
  right: 0;
}

.menu-trasnparent-btn {
  background: transparent;
  color: #FFFFFF;
  font-family: "Poppins", sans-serif;
  font-size: 1.18rem;
  font-weight: 500;
  line-height: 30px;
  border: none;
  border-radius: 1rem;
  padding: 1.25rem 0.375rem;
  gap: 0.5rem;
  width: 8.375rem;
  height: 7.625rem;
}

.green-btn {
  background: linear-gradient(82.9deg, #379237 0%, #82CD47 65.73%, #379237 100%);
  font-size: 1.18rem;
  color: #FFFFFF;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  border-radius: 0.5rem;
  gap: 0.625rem;
  padding: 0.938rem 2.563rem;
  border: none;
}
.green-btn:disabled {
  background: #333; 
}
.black-btn {
  background: linear-gradient(204.31deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.0378) 39.33%, rgba(255, 255, 255, 0) 62.52%), #252525;
  font-size: 1.18rem;
  color: #FFFFFF;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  border-radius: 0.5rem;
  gap: 0.625rem;
  padding: 0.938rem 2.563rem;
  border: none;
}

/*#endregion*/

/*#region homepage  cashier left section */
.calculator-section {
  background: linear-gradient(204.31deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.0378) 39.33%, rgba(255, 255, 255, 0) 62.52%), #252525;
  height: 100vh;
}
.calculator-section .barcode-section {
  border-top: 2px solid rgba(128, 128, 128, 0.4509803922);
  margin-top: 1rem;
}
.calculator-section .total-price-box {
  position: absolute;
  bottom: 0;
  padding: 1rem;
  left: 0;
  right: 0;
  background: linear-gradient(204.31deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.0378) 39.33%, rgba(255, 255, 255, 0) 62.52%), #252525;
}
.calculator-section .total-price-box .total {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.calculator-section .total-price-box .total span {
  color: #FFFFFF;
  font-size: 2rem;
  font-weight: 700;
  line-height: 53px;
  font-family: "Poppins", sans-serif;
}
.calculator-section .tool-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.calculator-section .cashier-products {
  padding-top: 1.5rem;
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: auto;
}
.calculator-section .cashier-products.cashier-products::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.calculator-section .cashier-products .cashier-products::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f3f3f3;
}
.calculator-section .cashier-products .cashier-products::-webkit-scrollbar-thumb {
  background-color: #f3f3f3;
  outline: 1px solid #f3f3f3;
}
.calculator-section .cashier-products .product-count-btn {
  background: linear-gradient(56.32deg, rgba(85, 85, 85, 0.3) 0%, rgba(240, 240, 240, 0.3) 100%);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: none;
}
.calculator-section .cashier-products .product-count-btn span {
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.calculator-section .cashier-products .product-name {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.8rem;
}
.calculator-section .cashier-products .product-name span {
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 27.65px;
  font-family: "Poppins", sans-serif;
}
.calculator-section .cashier-products .unit-price,
.calculator-section .cashier-products .price {
  border-radius: 0px;
  padding: 0.5rem;
  border-top: 2px solid gray;
  border-bottom: 2px solid gray;
  border-radius: 10%;
}
.calculator-section .cashier-products .unit-price span,
.calculator-section .cashier-products .price span {
  color: #FFFFFF;
  font-size: 1.375rem;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.calculator-section .cashier-products .edit-btn {
  background: linear-gradient(204.31deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.0378) 39.33%, rgba(255, 255, 255, 0) 62.52%), #252525;
  border: 1px solid rgba(216, 216, 216, 0.3);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.calculator-section .cashier-products .edit-btn span {
  color: #FFFFFF;
}
.calculator-section .cashier-products .delete-btn {
  background: linear-gradient(204.31deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.0378) 39.33%, rgba(255, 255, 255, 0) 62.52%), #252525;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.calculator-section .cashier-products .delete-btn span {
  color: #FFFFFF;
}

.cashier-calculator {
  background: linear-gradient(236.05deg, #ABABAB 0%, #E1E1E1 51.56%, #ABABAB 100%);
  border-radius: 15px;
}
.cashier-calculator .input-name {
  position: absolute;
  left: 2rem;
  top: 2rem;
  color: #8C8C8C;
  font-size: 1rem;
  font-weight: 500;
  line-height: 30px;
  text-transform: uppercase;
}
.cashier-calculator #priceInput {
  display: none;
}
.cashier-calculator .count-modal .btn {
  color: #fff;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 55px;
}
.cashier-calculator .count-modal .btn span {
  padding-right: 1rem;
}
.cashier-calculator .count-modal .btn-close {
  position: absolute;
  top: 0.7rem;
  right: 0.7rem;
  opacity: 1;
  transform: scale(0.8);
}
.cashier-calculator .count-modal .btn-approval {
  background: #00a41a;
}
.cashier-calculator .count-modal .btn-approval:hover {
  background: #00a41a !important;
  border: none !important;
  color: #fff !important;
}
.cashier-calculator .count-modal .btn-approval:focus {
  background: #00a41a !important;
  border: none !important;
  color: #fff !important;
}
.cashier-calculator .count-modal .btn-approval:active {
  background: #00a41a !important;
  border: none !important;
  color: #fff !important;
}
.cashier-calculator .count-modal .btn-cancel {
  background: #D62121;
}
.cashier-calculator .count-modal .btn-cancel:hover {
  background: #D62121 !important;
  border: none !important;
  color: #fff !important;
}
.cashier-calculator .count-modal .btn-cancel:focus {
  background: #D62121 !important;
  border: none !important;
  color: #fff !important;
}
.cashier-calculator .count-modal .btn-cancel:active {
  background: #D62121 !important;
  border: none !important;
  color: #fff !important;
}
.cashier-calculator .count-input {
  border: 1px solid rgba(154, 154, 154, 0.3803921569);
  padding: 0.7rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 100%;
  color: #252525;
  text-align: end;
  font-size: 1.18rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  line-height: 37.71px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
}
.cashier-calculator .keycap {
  background: #FFFFFF;
  border-radius: 8px;
  width: 78px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  line-height: 32.68px;
  color: #413A34;
  border: none;
  padding: 1.1rem;
}
.cashier-calculator .keycaps {
  --bs-gutter-x: 1rem !important;
}
.cashier-calculator .enter {
  height: 140px;
  background: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 30px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  line-height: 37.71px;
}
.cashier-calculator .reset {
  height: 120px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  line-height: 37.71px;
  flex-direction: column;
}

.count-input {
  border: 1px solid rgba(154, 154, 154, 0.3803921569);
  padding: 0.7rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  width: 100%;
  color: #252525;
  text-align: end;
  font-size: 1.18rem;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  line-height: 37.71px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
}

.input-name {
  position: absolute;
  left: 2rem;
  top: 2rem;
  color: #8C8C8C;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-transform: uppercase;
}

/*#endregion*/
 


/* */


/* material tab Color */
.mdc-tab-indicator--active .mat-icon {
  background: #11497d !important;
  color: #fff !important;
}

.mdc-tab-indicator--active {
  background: #11497d !important;
  color: #fff !important;
}

.mat-mdc-tab-labels {
  background: rgb(230, 231, 232) !important;
  border-top-left-radius: 5px !important;
}

#mat-tab-label-0-0 {
  border-top-left-radius: 5px !important;
}
/* material tab Color */

.notification-link {
  color: grey !important;
  font-size: 1rem;
}

.notification-link:visited {
  color: #5e5d5e !important;
}

.notifications {
  max-width: 3rem;
}

.notifications .red-dot {
  position: absolute;
  left: 31%;
  font-size: 2rem;
  bottom: 0%;
}

.notification-drop {
  font-family: "Ubuntu", sans-serif;
  color: #444;
}

.notification-drop .item {
  padding: 10px;
  font-size: 18px;
  position: relative;
  border-bottom: 1px solid #ddd;
}

.notification-drop .item:hover {
  cursor: pointer;
}

.notification-drop .item i {
  margin-left: 10px;
}

.notification-drop .item ul {
  display: none;
  position: absolute;
  top: 100%;
  background: #fff;
  left: -200px;
  right: 0;
  z-index: 1;
  border-top: 1px solid #ddd;
}

.notification-drop .item ul li {
  font-size: 16px;
  padding: 15px 0 15px 25px;
}

.notification-drop .item ul li:hover {
  background: #ddd;
  color: rgba(0, 0, 0, 0.8);
}

@media screen and (min-width: 500px) {
  .notification-drop {
    display: flex;
    justify-content: flex-end;
  }

  .notification-drop .item {
    border: none;
  }
}

.btn__badge {
  background: #ff5d5d;
  color: white;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 4px;
  padding: 1px 7px;
  border-radius: 50%;
}
#collapseBasic {
  background: white;
  padding: 1rem;
  position: absolute;
  top: 100%;
  right: 10%;
  /* min-height: 600px; */
  max-height: 500px;
  min-width: 350px;
  overflow-y: auto;
  background: #343a40!important;
  border-radius: 1rem;
}
#collapseBasic::-webkit-scrollbar {
  width: 1rem;
}
/* Track */
#collapseBasic::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
#collapseBasic::-webkit-scrollbar-thumb {
  background: #a09f9f;
  border-radius: 1rem;
}

/* Handle on hover */
#collapseBasic::-webkit-scrollbar-thumb:hover {
  background: #8a8a8a;
}

.pulse-button {
  box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
  -webkit-animation: pulse 1.5s infinite;
}

.pulse-button:hover {
  -webkit-animation: none;
}

@keyframes pulse {
  0% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  70% {
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    box-shadow: 0 0 0 50px rgba(255, 0, 0, 0);
  }

  100% {
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}
.notification-bell {
  font-size: 20px;
}